import { Cloudinary } from "cloudinary-core";

const CLOUD_ID = "hcabadqds";

function getBlurPNGURL(handle, client = undefined) {
  const cl = client || new Cloudinary({ cloud_name: CLOUD_ID });
  const imgURL = cl.url(handle, {
    format: "png",
    secure: true,
    flags: "png8",
    transformation: [
      {
        width: 8,
        height: 6,
        crop: "fill",
        quality: "60:420",
        gravity: "faces",
      },
    ],
  });
  return imgURL;
}

const staticLookup = {
  "iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAMAAADJ2y/JAAA": "{1}",
  iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGBAMAAAAMK8LIAAA: "{2}",
  iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAAAAADbboAnAAA: "{3}",
  iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGAQMAAADEy024AAA: "{4}",
  iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGAgMAAACDazdoAAA: "{5}",
};

const reverseLookup = Object.fromEntries(
  Object.entries(staticLookup).map(([k, v]) => [v, k])
);

function shortenBase64PNG(base64String) {
  const prefix = base64String.substring(0, 47);
  const remainder = base64String.substring(47);
  if (staticLookup[prefix]) {
    return staticLookup[prefix] + remainder;
  } else {
    return base64String;
  }
}

function unshortenBase64PNG(shortened) {
  if (shortened.startsWith("{")) {
    return reverseLookup[shortened.substring(0, 3)] + shortened.substring(3);
  } else {
    return shortened;
  }
}

const cl = new Cloudinary({ cloud_name: CLOUD_ID });

const UPLOAD_URL = `https://api.cloudinary.com/v1_1/${CLOUD_ID}/upload`;

async function uploadImage(data) {
  try {
    const result = await fetch(UPLOAD_URL, {
      body: JSON.stringify({ file: data, upload_preset: "qrgbqmal" }),
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
    }).then((r) => r.json());
    return result.public_id;
  } catch (e) {
    console.log("upload error", e);
    throw e;
  }
}

const constHandles = {
  shareApp: "",
  logo: "",
  icon: "",
  fallbackBlack: "",
};

function getURL({ handle, width, height = width, options = {} }) {
  return cl.url(handle, {
    format: "jpg",
    secure: true,
    transformation: [
      {
        width: width,
        height: height,
        crop: "fill",
        quality: "auto",
        flags: "immutable_cache",
      },
    ],
  });
}

export {
  getURL,
  uploadImage,
  getBlurPNGURL,
  shortenBase64PNG,
  unshortenBase64PNG,
  cl,
};
