/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum EventType_enum {
  Art = "Art",
  Concert = "Concert",
  Kids = "Kids",
  Movie = "Movie",
  Other = "Other",
  Party = "Party",
  Reading = "Reading",
  Sport = "Sport",
  Theater = "Theater",
}

export enum StatePublished_enum {
  DRAFT = "DRAFT",
  HIDDEN = "HIDDEN",
  INTERNAL = "INTERNAL",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

/**
 * unique or primary key constraints on table "cities"
 */
export enum cities_constraint {
  city_pkey = "city_pkey",
}

/**
 * update columns of table "cities"
 */
export enum cities_update_column {
  created_at = "created_at",
  id = "id",
  name = "name",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "collage_contains_occurrence"
 */
export enum collage_contains_occurrence_constraint {
  collage_contains_occurrence_id_key = "collage_contains_occurrence_id_key",
  collage_contains_occurrence_pkey = "collage_contains_occurrence_pkey",
}

/**
 * select columns of table "collage_contains_occurrence"
 */
export enum collage_contains_occurrence_select_column {
  collage_id = "collage_id",
  data = "data",
  external_rec_id = "external_rec_id",
  extra = "extra",
  id = "id",
  note = "note",
  occurrence_id = "occurrence_id",
  order = "order",
  owner_id = "owner_id",
}

/**
 * update columns of table "collage_contains_occurrence"
 */
export enum collage_contains_occurrence_update_column {
  collage_id = "collage_id",
  data = "data",
  external_rec_id = "external_rec_id",
  extra = "extra",
  id = "id",
  note = "note",
  occurrence_id = "occurrence_id",
  order = "order",
  owner_id = "owner_id",
}

/**
 * unique or primary key constraints on table "collages"
 */
export enum collages_constraint {
  collages_pkey = "collages_pkey",
}

/**
 * update columns of table "collages"
 */
export enum collages_update_column {
  created_at = "created_at",
  id = "id",
  internal = "internal",
  name = "name",
  owner_id = "owner_id",
  statePublished = "statePublished",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "eventTypes"
 */
export enum eventTypes_constraint {
  eventTypes_pkey = "eventTypes_pkey",
}

/**
 * update columns of table "eventTypes"
 */
export enum eventTypes_update_column {
  allDatesLabel = "allDatesLabel",
  disabled = "disabled",
  emoji = "emoji",
  eventType = "eventType",
  examples = "examples",
  gradient = "gradient",
  gradientIndex = "gradientIndex",
  hidden = "hidden",
  icon = "icon",
  icon_slug = "icon_slug",
  id = "id",
  label = "label",
  menu_index = "menu_index",
  name = "name",
  order = "order",
  search_hint = "search_hint",
  shortLabel = "shortLabel",
  sort_index = "sort_index",
}

/**
 * unique or primary key constraints on table "events"
 */
export enum events_constraint {
  events_matchID_key = "events_matchID_key",
  events_pkey = "events_pkey",
}

/**
 * select columns of table "events"
 */
export enum events_select_column {
  coverImage_alt = "coverImage_alt",
  coverImage_credit = "coverImage_credit",
  coverImage_handle = "coverImage_handle",
  coverImage_id = "coverImage_id",
  created_at = "created_at",
  data = "data",
  description = "description",
  eventType = "eventType",
  extra = "extra",
  familyFriendly = "familyFriendly",
  free = "free",
  hide = "hide",
  id = "id",
  image_base64 = "image_base64",
  image_blurhash = "image_blurhash",
  matchID = "matchID",
  mediaURLKey = "mediaURLKey",
  name = "name",
  notes = "notes",
  online = "online",
  onlineEventURL = "onlineEventURL",
  owner_id = "owner_id",
  placeID = "placeID",
  price = "price",
  profile_id = "profile_id",
  scraped = "scraped",
  search_hint = "search_hint",
  soundcloudURL = "soundcloudURL",
  spotifyURL = "spotifyURL",
  statePublished = "statePublished",
  temp = "temp",
  ticketURL = "ticketURL",
  updated_at = "updated_at",
  youtubeURL = "youtubeURL",
}

/**
 * select "events_aggregate_bool_exp_bool_and_arguments_columns" columns of table "events"
 */
export enum events_select_column_events_aggregate_bool_exp_bool_and_arguments_columns {
  familyFriendly = "familyFriendly",
  free = "free",
  hide = "hide",
  online = "online",
  scraped = "scraped",
  temp = "temp",
}

/**
 * select "events_aggregate_bool_exp_bool_or_arguments_columns" columns of table "events"
 */
export enum events_select_column_events_aggregate_bool_exp_bool_or_arguments_columns {
  familyFriendly = "familyFriendly",
  free = "free",
  hide = "hide",
  online = "online",
  scraped = "scraped",
  temp = "temp",
}

/**
 * update columns of table "events"
 */
export enum events_update_column {
  coverImage_alt = "coverImage_alt",
  coverImage_credit = "coverImage_credit",
  coverImage_handle = "coverImage_handle",
  coverImage_id = "coverImage_id",
  created_at = "created_at",
  data = "data",
  description = "description",
  eventType = "eventType",
  extra = "extra",
  familyFriendly = "familyFriendly",
  free = "free",
  hide = "hide",
  id = "id",
  image_base64 = "image_base64",
  image_blurhash = "image_blurhash",
  matchID = "matchID",
  mediaURLKey = "mediaURLKey",
  name = "name",
  notes = "notes",
  online = "online",
  onlineEventURL = "onlineEventURL",
  owner_id = "owner_id",
  placeID = "placeID",
  price = "price",
  profile_id = "profile_id",
  scraped = "scraped",
  search_hint = "search_hint",
  soundcloudURL = "soundcloudURL",
  spotifyURL = "spotifyURL",
  statePublished = "statePublished",
  temp = "temp",
  ticketURL = "ticketURL",
  updated_at = "updated_at",
  youtubeURL = "youtubeURL",
}

/**
 * unique or primary key constraints on table "followed"
 */
export enum followed_constraint {
  followed_pkey = "followed_pkey",
}

/**
 * select columns of table "followed"
 */
export enum followed_select_column {
  followed_at = "followed_at",
  profile_id = "profile_id",
  user_id = "user_id",
}

/**
 * update columns of table "followed"
 */
export enum followed_update_column {
  followed_at = "followed_at",
  profile_id = "profile_id",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "occurrences"
 */
export enum occurrences_constraint {
  occurrences_event_id_startDate_owner_id_key = "occurrences_event_id_startDate_owner_id_key",
  occurrences_matchID_key = "occurrences_matchID_key",
  occurrences_pkey = "occurrences_pkey",
}

/**
 * select columns of table "occurrences"
 */
export enum occurrences_select_column {
  cancelled = "cancelled",
  created_at = "created_at",
  data = "data",
  endDate = "endDate",
  event_id = "event_id",
  extra = "extra",
  featured = "featured",
  id = "id",
  language = "language",
  matchID = "matchID",
  owner_id = "owner_id",
  scraped = "scraped",
  startDate = "startDate",
  temp = "temp",
  title = "title",
  updated_at = "updated_at",
}

/**
 * select "occurrences_aggregate_bool_exp_bool_and_arguments_columns" columns of table "occurrences"
 */
export enum occurrences_select_column_occurrences_aggregate_bool_exp_bool_and_arguments_columns {
  cancelled = "cancelled",
  featured = "featured",
  scraped = "scraped",
  temp = "temp",
}

/**
 * select "occurrences_aggregate_bool_exp_bool_or_arguments_columns" columns of table "occurrences"
 */
export enum occurrences_select_column_occurrences_aggregate_bool_exp_bool_or_arguments_columns {
  cancelled = "cancelled",
  featured = "featured",
  scraped = "scraped",
  temp = "temp",
}

/**
 * update columns of table "occurrences"
 */
export enum occurrences_update_column {
  cancelled = "cancelled",
  created_at = "created_at",
  data = "data",
  endDate = "endDate",
  event_id = "event_id",
  extra = "extra",
  featured = "featured",
  id = "id",
  language = "language",
  matchID = "matchID",
  owner_id = "owner_id",
  scraped = "scraped",
  startDate = "startDate",
  temp = "temp",
  title = "title",
  updated_at = "updated_at",
}

/**
 * column ordering options
 */
export enum order_by {
  asc = "asc",
  asc_nulls_first = "asc_nulls_first",
  asc_nulls_last = "asc_nulls_last",
  desc = "desc",
  desc_nulls_first = "desc_nulls_first",
  desc_nulls_last = "desc_nulls_last",
}

/**
 * unique or primary key constraints on table "placeInfo"
 */
export enum placeInfo_constraint {
  locationInfo_pkey = "locationInfo_pkey",
}

/**
 * update columns of table "placeInfo"
 */
export enum placeInfo_update_column {
  accessible = "accessible",
  address = "address",
  fetched_at = "fetched_at",
  name = "name",
  open_url = "open_url",
  placeID = "placeID",
}

/**
 * unique or primary key constraints on table "posts"
 */
export enum posts_constraint {
  posts_pkey = "posts_pkey",
}

/**
 * select columns of table "posts"
 */
export enum posts_select_column {
  created_at = "created_at",
  data = "data",
  extra = "extra",
  id = "id",
  owner_id = "owner_id",
  post_type = "post_type",
  profile_id = "profile_id",
  push = "push",
  state_published = "state_published",
  text = "text",
  updated_at = "updated_at",
}

/**
 * select "posts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "posts"
 */
export enum posts_select_column_posts_aggregate_bool_exp_bool_and_arguments_columns {
  push = "push",
}

/**
 * select "posts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "posts"
 */
export enum posts_select_column_posts_aggregate_bool_exp_bool_or_arguments_columns {
  push = "push",
}

/**
 * update columns of table "posts"
 */
export enum posts_update_column {
  created_at = "created_at",
  data = "data",
  extra = "extra",
  id = "id",
  owner_id = "owner_id",
  post_type = "post_type",
  profile_id = "profile_id",
  push = "push",
  state_published = "state_published",
  text = "text",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "profiles"
 */
export enum profiles_constraint {
  index_slug = "index_slug",
  profiles_pkey = "profiles_pkey",
  profiles_slug_key = "profiles_slug_key",
}

/**
 * update columns of table "profiles"
 */
export enum profiles_update_column {
  allowed_users = "allowed_users",
  approved = "approved",
  city_id = "city_id",
  claimed = "claimed",
  contact = "contact",
  created_at = "created_at",
  description = "description",
  facebookPage_url = "facebookPage_url",
  id = "id",
  image_handle = "image_handle",
  instagram_username = "instagram_username",
  name = "name",
  notes = "notes",
  owner_id = "owner_id",
  page_config = "page_config",
  phone = "phone",
  previewID = "previewID",
  search_hint = "search_hint",
  shouldScrape = "shouldScrape",
  slug = "slug",
  tagline = "tagline",
  twitter_username = "twitter_username",
  updated_at = "updated_at",
  website = "website",
}

/**
 * Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: boolean[] | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: boolean[] | null;
}

/**
 * Boolean expression to compare columns of type "EventType_enum". All fields are combined with logical 'AND'.
 */
export interface EventType_enum_comparison_exp {
  _eq?: EventType_enum | null;
  _in?: EventType_enum[] | null;
  _is_null?: boolean | null;
  _neq?: EventType_enum | null;
  _nin?: EventType_enum[] | null;
}

/**
 * Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * Boolean expression to compare columns of type "StatePublished_enum". All fields are combined with logical 'AND'.
 */
export interface StatePublished_enum_comparison_exp {
  _eq?: StatePublished_enum | null;
  _in?: StatePublished_enum[] | null;
  _is_null?: boolean | null;
  _neq?: StatePublished_enum | null;
  _nin?: StatePublished_enum[] | null;
}

/**
 * Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _iregex?: string | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _niregex?: string | null;
  _nlike?: string | null;
  _nregex?: string | null;
  _nsimilar?: string | null;
  _regex?: string | null;
  _similar?: string | null;
}

/**
 * Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'.
 */
export interface bigint_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to filter rows from the table "cities". All fields are combined with a logical 'AND'.
 */
export interface cities_bool_exp {
  _and?: cities_bool_exp[] | null;
  _not?: cities_bool_exp | null;
  _or?: cities_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "cities"
 */
export interface cities_insert_input {
  created_at?: any | null;
  id?: string | null;
  name?: string | null;
  updated_at?: any | null;
}

/**
 * input type for inserting object relation for remote table "cities"
 */
export interface cities_obj_rel_insert_input {
  data: cities_insert_input;
  on_conflict?: cities_on_conflict | null;
}

/**
 * on_conflict condition type for table "cities"
 */
export interface cities_on_conflict {
  constraint: cities_constraint;
  update_columns: cities_update_column[];
  where?: cities_bool_exp | null;
}

/**
 * Ordering options when selecting data from "cities".
 */
export interface cities_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  name?: order_by | null;
  updated_at?: order_by | null;
}

export interface collage_contains_occurrence_aggregate_bool_exp {
  count?: collage_contains_occurrence_aggregate_bool_exp_count | null;
}

export interface collage_contains_occurrence_aggregate_bool_exp_count {
  arguments?: collage_contains_occurrence_select_column[] | null;
  distinct?: boolean | null;
  filter?: collage_contains_occurrence_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_aggregate_order_by {
  avg?: collage_contains_occurrence_avg_order_by | null;
  count?: order_by | null;
  max?: collage_contains_occurrence_max_order_by | null;
  min?: collage_contains_occurrence_min_order_by | null;
  stddev?: collage_contains_occurrence_stddev_order_by | null;
  stddev_pop?: collage_contains_occurrence_stddev_pop_order_by | null;
  stddev_samp?: collage_contains_occurrence_stddev_samp_order_by | null;
  sum?: collage_contains_occurrence_sum_order_by | null;
  var_pop?: collage_contains_occurrence_var_pop_order_by | null;
  var_samp?: collage_contains_occurrence_var_samp_order_by | null;
  variance?: collage_contains_occurrence_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_arr_rel_insert_input {
  data: collage_contains_occurrence_insert_input[];
  on_conflict?: collage_contains_occurrence_on_conflict | null;
}

/**
 * order by avg() on columns of table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_avg_order_by {
  occurrence_id?: order_by | null;
  order?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "collage_contains_occurrence".
 * All fields are combined with a logical 'AND'.
 */
export interface collage_contains_occurrence_bool_exp {
  _and?: collage_contains_occurrence_bool_exp[] | null;
  _not?: collage_contains_occurrence_bool_exp | null;
  _or?: collage_contains_occurrence_bool_exp[] | null;
  collage?: collages_bool_exp | null;
  collage_id?: String_comparison_exp | null;
  data?: jsonb_comparison_exp | null;
  external_rec_id?: uuid_comparison_exp | null;
  extra?: jsonb_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  note?: String_comparison_exp | null;
  occurrence?: occurrences_bool_exp | null;
  occurrence_id?: bigint_comparison_exp | null;
  order?: Int_comparison_exp | null;
  owner_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_insert_input {
  collage?: collages_obj_rel_insert_input | null;
  collage_id?: string | null;
  data?: any | null;
  external_rec_id?: any | null;
  extra?: any | null;
  id?: any | null;
  note?: string | null;
  occurrence?: occurrences_obj_rel_insert_input | null;
  occurrence_id?: any | null;
  order?: number | null;
  owner_id?: string | null;
}

/**
 * order by max() on columns of table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_max_order_by {
  collage_id?: order_by | null;
  external_rec_id?: order_by | null;
  id?: order_by | null;
  note?: order_by | null;
  occurrence_id?: order_by | null;
  order?: order_by | null;
  owner_id?: order_by | null;
}

/**
 * order by min() on columns of table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_min_order_by {
  collage_id?: order_by | null;
  external_rec_id?: order_by | null;
  id?: order_by | null;
  note?: order_by | null;
  occurrence_id?: order_by | null;
  order?: order_by | null;
  owner_id?: order_by | null;
}

/**
 * on_conflict condition type for table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_on_conflict {
  constraint: collage_contains_occurrence_constraint;
  update_columns: collage_contains_occurrence_update_column[];
  where?: collage_contains_occurrence_bool_exp | null;
}

/**
 * order by stddev() on columns of table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_stddev_order_by {
  occurrence_id?: order_by | null;
  order?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_stddev_pop_order_by {
  occurrence_id?: order_by | null;
  order?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_stddev_samp_order_by {
  occurrence_id?: order_by | null;
  order?: order_by | null;
}

/**
 * order by sum() on columns of table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_sum_order_by {
  occurrence_id?: order_by | null;
  order?: order_by | null;
}

/**
 * order by var_pop() on columns of table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_var_pop_order_by {
  occurrence_id?: order_by | null;
  order?: order_by | null;
}

/**
 * order by var_samp() on columns of table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_var_samp_order_by {
  occurrence_id?: order_by | null;
  order?: order_by | null;
}

/**
 * order by variance() on columns of table "collage_contains_occurrence"
 */
export interface collage_contains_occurrence_variance_order_by {
  occurrence_id?: order_by | null;
  order?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "collages". All fields are combined with a logical 'AND'.
 */
export interface collages_bool_exp {
  _and?: collages_bool_exp[] | null;
  _not?: collages_bool_exp | null;
  _or?: collages_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: String_comparison_exp | null;
  internal?: Boolean_comparison_exp | null;
  name?: String_comparison_exp | null;
  occurrences?: collage_contains_occurrence_bool_exp | null;
  occurrences_aggregate?: collage_contains_occurrence_aggregate_bool_exp | null;
  owner_id?: String_comparison_exp | null;
  statePublished?: StatePublished_enum_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "collages"
 */
export interface collages_insert_input {
  created_at?: any | null;
  id?: string | null;
  internal?: boolean | null;
  name?: string | null;
  occurrences?: collage_contains_occurrence_arr_rel_insert_input | null;
  owner_id?: string | null;
  statePublished?: StatePublished_enum | null;
  updated_at?: any | null;
}

/**
 * input type for inserting object relation for remote table "collages"
 */
export interface collages_obj_rel_insert_input {
  data: collages_insert_input;
  on_conflict?: collages_on_conflict | null;
}

/**
 * on_conflict condition type for table "collages"
 */
export interface collages_on_conflict {
  constraint: collages_constraint;
  update_columns: collages_update_column[];
  where?: collages_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "eventTypes". All fields are combined with a logical 'AND'.
 */
export interface eventTypes_bool_exp {
  _and?: eventTypes_bool_exp[] | null;
  _not?: eventTypes_bool_exp | null;
  _or?: eventTypes_bool_exp[] | null;
  allDatesLabel?: String_comparison_exp | null;
  disabled?: Boolean_comparison_exp | null;
  emoji?: String_comparison_exp | null;
  eventType?: EventType_enum_comparison_exp | null;
  examples?: String_comparison_exp | null;
  gradient?: String_comparison_exp | null;
  gradientIndex?: Int_comparison_exp | null;
  hidden?: Boolean_comparison_exp | null;
  icon?: String_comparison_exp | null;
  icon_slug?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  label?: String_comparison_exp | null;
  menu_index?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  order?: Int_comparison_exp | null;
  search_hint?: String_comparison_exp | null;
  shortLabel?: String_comparison_exp | null;
  sort_index?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "eventTypes"
 */
export interface eventTypes_insert_input {
  allDatesLabel?: string | null;
  disabled?: boolean | null;
  emoji?: string | null;
  eventType?: EventType_enum | null;
  examples?: string | null;
  gradient?: string | null;
  gradientIndex?: number | null;
  hidden?: boolean | null;
  icon?: string | null;
  icon_slug?: string | null;
  id?: any | null;
  label?: string | null;
  menu_index?: number | null;
  name?: string | null;
  order?: number | null;
  search_hint?: string | null;
  shortLabel?: string | null;
  sort_index?: number | null;
}

/**
 * input type for inserting object relation for remote table "eventTypes"
 */
export interface eventTypes_obj_rel_insert_input {
  data: eventTypes_insert_input;
  on_conflict?: eventTypes_on_conflict | null;
}

/**
 * on_conflict condition type for table "eventTypes"
 */
export interface eventTypes_on_conflict {
  constraint: eventTypes_constraint;
  update_columns: eventTypes_update_column[];
  where?: eventTypes_bool_exp | null;
}

/**
 * Ordering options when selecting data from "eventTypes".
 */
export interface eventTypes_order_by {
  allDatesLabel?: order_by | null;
  disabled?: order_by | null;
  emoji?: order_by | null;
  eventType?: order_by | null;
  examples?: order_by | null;
  gradient?: order_by | null;
  gradientIndex?: order_by | null;
  hidden?: order_by | null;
  icon?: order_by | null;
  icon_slug?: order_by | null;
  id?: order_by | null;
  label?: order_by | null;
  menu_index?: order_by | null;
  name?: order_by | null;
  order?: order_by | null;
  search_hint?: order_by | null;
  shortLabel?: order_by | null;
  sort_index?: order_by | null;
}

export interface events_aggregate_bool_exp {
  bool_and?: events_aggregate_bool_exp_bool_and | null;
  bool_or?: events_aggregate_bool_exp_bool_or | null;
  count?: events_aggregate_bool_exp_count | null;
}

export interface events_aggregate_bool_exp_bool_and {
  arguments: events_select_column_events_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: events_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface events_aggregate_bool_exp_bool_or {
  arguments: events_select_column_events_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: events_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface events_aggregate_bool_exp_count {
  arguments?: events_select_column[] | null;
  distinct?: boolean | null;
  filter?: events_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "events"
 */
export interface events_aggregate_order_by {
  avg?: events_avg_order_by | null;
  count?: order_by | null;
  max?: events_max_order_by | null;
  min?: events_min_order_by | null;
  stddev?: events_stddev_order_by | null;
  stddev_pop?: events_stddev_pop_order_by | null;
  stddev_samp?: events_stddev_samp_order_by | null;
  sum?: events_sum_order_by | null;
  var_pop?: events_var_pop_order_by | null;
  var_samp?: events_var_samp_order_by | null;
  variance?: events_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "events"
 */
export interface events_arr_rel_insert_input {
  data: events_insert_input[];
  on_conflict?: events_on_conflict | null;
}

/**
 * order by avg() on columns of table "events"
 */
export interface events_avg_order_by {
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'.
 */
export interface events_bool_exp {
  _and?: events_bool_exp[] | null;
  _not?: events_bool_exp | null;
  _or?: events_bool_exp[] | null;
  coverImage_alt?: String_comparison_exp | null;
  coverImage_credit?: String_comparison_exp | null;
  coverImage_handle?: String_comparison_exp | null;
  coverImage_id?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  data?: jsonb_comparison_exp | null;
  description?: String_comparison_exp | null;
  eventType?: EventType_enum_comparison_exp | null;
  extra?: jsonb_comparison_exp | null;
  familyFriendly?: Boolean_comparison_exp | null;
  free?: Boolean_comparison_exp | null;
  hide?: Boolean_comparison_exp | null;
  id?: bigint_comparison_exp | null;
  image_base64?: String_comparison_exp | null;
  image_blurhash?: String_comparison_exp | null;
  matchID?: String_comparison_exp | null;
  mediaURLKey?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  notes?: String_comparison_exp | null;
  occurrences?: occurrences_bool_exp | null;
  occurrences_aggregate?: occurrences_aggregate_bool_exp | null;
  online?: Boolean_comparison_exp | null;
  onlineEventURL?: String_comparison_exp | null;
  owner_id?: String_comparison_exp | null;
  placeID?: String_comparison_exp | null;
  placeInfo?: placeInfo_bool_exp | null;
  price?: String_comparison_exp | null;
  profile?: profiles_bool_exp | null;
  profile_id?: String_comparison_exp | null;
  scraped?: Boolean_comparison_exp | null;
  search_hint?: String_comparison_exp | null;
  soundcloudURL?: String_comparison_exp | null;
  spotifyURL?: String_comparison_exp | null;
  statePublished?: StatePublished_enum_comparison_exp | null;
  temp?: Boolean_comparison_exp | null;
  ticketURL?: String_comparison_exp | null;
  type?: eventTypes_bool_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  youtubeURL?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "events"
 */
export interface events_insert_input {
  coverImage_alt?: string | null;
  coverImage_credit?: string | null;
  coverImage_handle?: string | null;
  coverImage_id?: string | null;
  created_at?: any | null;
  data?: any | null;
  description?: string | null;
  eventType?: EventType_enum | null;
  extra?: any | null;
  familyFriendly?: boolean | null;
  free?: boolean | null;
  hide?: boolean | null;
  id?: any | null;
  image_base64?: string | null;
  image_blurhash?: string | null;
  matchID?: string | null;
  mediaURLKey?: string | null;
  name?: string | null;
  notes?: string | null;
  occurrences?: occurrences_arr_rel_insert_input | null;
  online?: boolean | null;
  onlineEventURL?: string | null;
  owner_id?: string | null;
  placeID?: string | null;
  placeInfo?: placeInfo_obj_rel_insert_input | null;
  price?: string | null;
  profile?: profiles_obj_rel_insert_input | null;
  profile_id?: string | null;
  scraped?: boolean | null;
  search_hint?: string | null;
  soundcloudURL?: string | null;
  spotifyURL?: string | null;
  statePublished?: StatePublished_enum | null;
  temp?: boolean | null;
  ticketURL?: string | null;
  type?: eventTypes_obj_rel_insert_input | null;
  updated_at?: any | null;
  youtubeURL?: string | null;
}

/**
 * order by max() on columns of table "events"
 */
export interface events_max_order_by {
  coverImage_alt?: order_by | null;
  coverImage_credit?: order_by | null;
  coverImage_handle?: order_by | null;
  coverImage_id?: order_by | null;
  created_at?: order_by | null;
  description?: order_by | null;
  id?: order_by | null;
  image_base64?: order_by | null;
  image_blurhash?: order_by | null;
  matchID?: order_by | null;
  mediaURLKey?: order_by | null;
  name?: order_by | null;
  notes?: order_by | null;
  onlineEventURL?: order_by | null;
  owner_id?: order_by | null;
  placeID?: order_by | null;
  price?: order_by | null;
  profile_id?: order_by | null;
  search_hint?: order_by | null;
  soundcloudURL?: order_by | null;
  spotifyURL?: order_by | null;
  ticketURL?: order_by | null;
  updated_at?: order_by | null;
  youtubeURL?: order_by | null;
}

/**
 * order by min() on columns of table "events"
 */
export interface events_min_order_by {
  coverImage_alt?: order_by | null;
  coverImage_credit?: order_by | null;
  coverImage_handle?: order_by | null;
  coverImage_id?: order_by | null;
  created_at?: order_by | null;
  description?: order_by | null;
  id?: order_by | null;
  image_base64?: order_by | null;
  image_blurhash?: order_by | null;
  matchID?: order_by | null;
  mediaURLKey?: order_by | null;
  name?: order_by | null;
  notes?: order_by | null;
  onlineEventURL?: order_by | null;
  owner_id?: order_by | null;
  placeID?: order_by | null;
  price?: order_by | null;
  profile_id?: order_by | null;
  search_hint?: order_by | null;
  soundcloudURL?: order_by | null;
  spotifyURL?: order_by | null;
  ticketURL?: order_by | null;
  updated_at?: order_by | null;
  youtubeURL?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "events"
 */
export interface events_obj_rel_insert_input {
  data: events_insert_input;
  on_conflict?: events_on_conflict | null;
}

/**
 * on_conflict condition type for table "events"
 */
export interface events_on_conflict {
  constraint: events_constraint;
  update_columns: events_update_column[];
  where?: events_bool_exp | null;
}

/**
 * Ordering options when selecting data from "events".
 */
export interface events_order_by {
  coverImage_alt?: order_by | null;
  coverImage_credit?: order_by | null;
  coverImage_handle?: order_by | null;
  coverImage_id?: order_by | null;
  created_at?: order_by | null;
  data?: order_by | null;
  description?: order_by | null;
  eventType?: order_by | null;
  extra?: order_by | null;
  familyFriendly?: order_by | null;
  free?: order_by | null;
  hide?: order_by | null;
  id?: order_by | null;
  image_base64?: order_by | null;
  image_blurhash?: order_by | null;
  matchID?: order_by | null;
  mediaURLKey?: order_by | null;
  name?: order_by | null;
  notes?: order_by | null;
  occurrences_aggregate?: occurrences_aggregate_order_by | null;
  online?: order_by | null;
  onlineEventURL?: order_by | null;
  owner_id?: order_by | null;
  placeID?: order_by | null;
  placeInfo?: placeInfo_order_by | null;
  price?: order_by | null;
  profile?: profiles_order_by | null;
  profile_id?: order_by | null;
  scraped?: order_by | null;
  search_hint?: order_by | null;
  soundcloudURL?: order_by | null;
  spotifyURL?: order_by | null;
  statePublished?: order_by | null;
  temp?: order_by | null;
  ticketURL?: order_by | null;
  type?: eventTypes_order_by | null;
  updated_at?: order_by | null;
  youtubeURL?: order_by | null;
}

/**
 * input type for updating data in table "events"
 */
export interface events_set_input {
  coverImage_alt?: string | null;
  coverImage_credit?: string | null;
  coverImage_handle?: string | null;
  coverImage_id?: string | null;
  created_at?: any | null;
  data?: any | null;
  description?: string | null;
  eventType?: EventType_enum | null;
  extra?: any | null;
  familyFriendly?: boolean | null;
  free?: boolean | null;
  hide?: boolean | null;
  id?: any | null;
  image_base64?: string | null;
  image_blurhash?: string | null;
  matchID?: string | null;
  mediaURLKey?: string | null;
  name?: string | null;
  notes?: string | null;
  online?: boolean | null;
  onlineEventURL?: string | null;
  owner_id?: string | null;
  placeID?: string | null;
  price?: string | null;
  profile_id?: string | null;
  scraped?: boolean | null;
  search_hint?: string | null;
  soundcloudURL?: string | null;
  spotifyURL?: string | null;
  statePublished?: StatePublished_enum | null;
  temp?: boolean | null;
  ticketURL?: string | null;
  updated_at?: any | null;
  youtubeURL?: string | null;
}

/**
 * order by stddev() on columns of table "events"
 */
export interface events_stddev_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "events"
 */
export interface events_stddev_pop_order_by {
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "events"
 */
export interface events_stddev_samp_order_by {
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "events"
 */
export interface events_sum_order_by {
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "events"
 */
export interface events_var_pop_order_by {
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "events"
 */
export interface events_var_samp_order_by {
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "events"
 */
export interface events_variance_order_by {
  id?: order_by | null;
}

export interface followed_aggregate_bool_exp {
  count?: followed_aggregate_bool_exp_count | null;
}

export interface followed_aggregate_bool_exp_count {
  arguments?: followed_select_column[] | null;
  distinct?: boolean | null;
  filter?: followed_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "followed"
 */
export interface followed_aggregate_order_by {
  count?: order_by | null;
  max?: followed_max_order_by | null;
  min?: followed_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "followed"
 */
export interface followed_arr_rel_insert_input {
  data: followed_insert_input[];
  on_conflict?: followed_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "followed". All fields are combined with a logical 'AND'.
 */
export interface followed_bool_exp {
  _and?: followed_bool_exp[] | null;
  _not?: followed_bool_exp | null;
  _or?: followed_bool_exp[] | null;
  followed_at?: timestamptz_comparison_exp | null;
  profile?: profiles_bool_exp | null;
  profile_id?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "followed"
 */
export interface followed_insert_input {
  followed_at?: any | null;
  profile?: profiles_obj_rel_insert_input | null;
  profile_id?: string | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "followed"
 */
export interface followed_max_order_by {
  followed_at?: order_by | null;
  profile_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "followed"
 */
export interface followed_min_order_by {
  followed_at?: order_by | null;
  profile_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on_conflict condition type for table "followed"
 */
export interface followed_on_conflict {
  constraint: followed_constraint;
  update_columns: followed_update_column[];
  where?: followed_bool_exp | null;
}

export interface jsonb_cast_exp {
  String?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'.
 */
export interface jsonb_comparison_exp {
  _cast?: jsonb_cast_exp | null;
  _contained_in?: any | null;
  _contains?: any | null;
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _has_key?: string | null;
  _has_keys_all?: string[] | null;
  _has_keys_any?: string[] | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'.
 */
export interface numeric_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

export interface occurrences_aggregate_bool_exp {
  bool_and?: occurrences_aggregate_bool_exp_bool_and | null;
  bool_or?: occurrences_aggregate_bool_exp_bool_or | null;
  count?: occurrences_aggregate_bool_exp_count | null;
}

export interface occurrences_aggregate_bool_exp_bool_and {
  arguments: occurrences_select_column_occurrences_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: occurrences_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface occurrences_aggregate_bool_exp_bool_or {
  arguments: occurrences_select_column_occurrences_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: occurrences_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface occurrences_aggregate_bool_exp_count {
  arguments?: occurrences_select_column[] | null;
  distinct?: boolean | null;
  filter?: occurrences_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "occurrences"
 */
export interface occurrences_aggregate_order_by {
  avg?: occurrences_avg_order_by | null;
  count?: order_by | null;
  max?: occurrences_max_order_by | null;
  min?: occurrences_min_order_by | null;
  stddev?: occurrences_stddev_order_by | null;
  stddev_pop?: occurrences_stddev_pop_order_by | null;
  stddev_samp?: occurrences_stddev_samp_order_by | null;
  sum?: occurrences_sum_order_by | null;
  var_pop?: occurrences_var_pop_order_by | null;
  var_samp?: occurrences_var_samp_order_by | null;
  variance?: occurrences_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "occurrences"
 */
export interface occurrences_arr_rel_insert_input {
  data: occurrences_insert_input[];
  on_conflict?: occurrences_on_conflict | null;
}

/**
 * order by avg() on columns of table "occurrences"
 */
export interface occurrences_avg_order_by {
  event_id?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "occurrences". All fields are combined with a logical 'AND'.
 */
export interface occurrences_bool_exp {
  _and?: occurrences_bool_exp[] | null;
  _not?: occurrences_bool_exp | null;
  _or?: occurrences_bool_exp[] | null;
  cancelled?: Boolean_comparison_exp | null;
  collages?: collage_contains_occurrence_bool_exp | null;
  collages_aggregate?: collage_contains_occurrence_aggregate_bool_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  data?: jsonb_comparison_exp | null;
  endDate?: timestamptz_comparison_exp | null;
  event?: events_bool_exp | null;
  event_id?: bigint_comparison_exp | null;
  extra?: jsonb_comparison_exp | null;
  featured?: Boolean_comparison_exp | null;
  id?: bigint_comparison_exp | null;
  language?: String_comparison_exp | null;
  matchID?: String_comparison_exp | null;
  owner_id?: String_comparison_exp | null;
  past?: Boolean_comparison_exp | null;
  saved?: Boolean_comparison_exp | null;
  saved_count?: Int_comparison_exp | null;
  scraped?: Boolean_comparison_exp | null;
  startDate?: timestamptz_comparison_exp | null;
  temp?: Boolean_comparison_exp | null;
  title?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "occurrences"
 */
export interface occurrences_insert_input {
  cancelled?: boolean | null;
  collages?: collage_contains_occurrence_arr_rel_insert_input | null;
  created_at?: any | null;
  data?: any | null;
  endDate?: any | null;
  event?: events_obj_rel_insert_input | null;
  event_id?: any | null;
  extra?: any | null;
  featured?: boolean | null;
  id?: any | null;
  language?: string | null;
  matchID?: string | null;
  owner_id?: string | null;
  scraped?: boolean | null;
  startDate?: any | null;
  temp?: boolean | null;
  title?: string | null;
  updated_at?: any | null;
}

/**
 * order by max() on columns of table "occurrences"
 */
export interface occurrences_max_order_by {
  created_at?: order_by | null;
  endDate?: order_by | null;
  event_id?: order_by | null;
  id?: order_by | null;
  language?: order_by | null;
  matchID?: order_by | null;
  owner_id?: order_by | null;
  startDate?: order_by | null;
  title?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "occurrences"
 */
export interface occurrences_min_order_by {
  created_at?: order_by | null;
  endDate?: order_by | null;
  event_id?: order_by | null;
  id?: order_by | null;
  language?: order_by | null;
  matchID?: order_by | null;
  owner_id?: order_by | null;
  startDate?: order_by | null;
  title?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for inserting object relation for remote table "occurrences"
 */
export interface occurrences_obj_rel_insert_input {
  data: occurrences_insert_input;
  on_conflict?: occurrences_on_conflict | null;
}

/**
 * on_conflict condition type for table "occurrences"
 */
export interface occurrences_on_conflict {
  constraint: occurrences_constraint;
  update_columns: occurrences_update_column[];
  where?: occurrences_bool_exp | null;
}

/**
 * Ordering options when selecting data from "occurrences".
 */
export interface occurrences_order_by {
  cancelled?: order_by | null;
  collages_aggregate?: collage_contains_occurrence_aggregate_order_by | null;
  created_at?: order_by | null;
  data?: order_by | null;
  endDate?: order_by | null;
  event?: events_order_by | null;
  event_id?: order_by | null;
  extra?: order_by | null;
  featured?: order_by | null;
  id?: order_by | null;
  language?: order_by | null;
  matchID?: order_by | null;
  owner_id?: order_by | null;
  past?: order_by | null;
  saved?: order_by | null;
  saved_count?: order_by | null;
  scraped?: order_by | null;
  startDate?: order_by | null;
  temp?: order_by | null;
  title?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * input type for updating data in table "occurrences"
 */
export interface occurrences_set_input {
  cancelled?: boolean | null;
  created_at?: any | null;
  data?: any | null;
  endDate?: any | null;
  event_id?: any | null;
  extra?: any | null;
  featured?: boolean | null;
  id?: any | null;
  language?: string | null;
  matchID?: string | null;
  owner_id?: string | null;
  scraped?: boolean | null;
  startDate?: any | null;
  temp?: boolean | null;
  title?: string | null;
  updated_at?: any | null;
}

/**
 * order by stddev() on columns of table "occurrences"
 */
export interface occurrences_stddev_order_by {
  event_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "occurrences"
 */
export interface occurrences_stddev_pop_order_by {
  event_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "occurrences"
 */
export interface occurrences_stddev_samp_order_by {
  event_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by sum() on columns of table "occurrences"
 */
export interface occurrences_sum_order_by {
  event_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_pop() on columns of table "occurrences"
 */
export interface occurrences_var_pop_order_by {
  event_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by var_samp() on columns of table "occurrences"
 */
export interface occurrences_var_samp_order_by {
  event_id?: order_by | null;
  id?: order_by | null;
}

/**
 * order by variance() on columns of table "occurrences"
 */
export interface occurrences_variance_order_by {
  event_id?: order_by | null;
  id?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "placeInfo". All fields are combined with a logical 'AND'.
 */
export interface placeInfo_bool_exp {
  _and?: placeInfo_bool_exp[] | null;
  _not?: placeInfo_bool_exp | null;
  _or?: placeInfo_bool_exp[] | null;
  accessible?: numeric_comparison_exp | null;
  address?: String_comparison_exp | null;
  fetched_at?: timestamptz_comparison_exp | null;
  name?: String_comparison_exp | null;
  open_url?: String_comparison_exp | null;
  placeID?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "placeInfo"
 */
export interface placeInfo_insert_input {
  accessible?: any | null;
  address?: string | null;
  fetched_at?: any | null;
  name?: string | null;
  open_url?: string | null;
  placeID?: string | null;
}

/**
 * input type for inserting object relation for remote table "placeInfo"
 */
export interface placeInfo_obj_rel_insert_input {
  data: placeInfo_insert_input;
  on_conflict?: placeInfo_on_conflict | null;
}

/**
 * on_conflict condition type for table "placeInfo"
 */
export interface placeInfo_on_conflict {
  constraint: placeInfo_constraint;
  update_columns: placeInfo_update_column[];
  where?: placeInfo_bool_exp | null;
}

/**
 * Ordering options when selecting data from "placeInfo".
 */
export interface placeInfo_order_by {
  accessible?: order_by | null;
  address?: order_by | null;
  fetched_at?: order_by | null;
  name?: order_by | null;
  open_url?: order_by | null;
  placeID?: order_by | null;
}

export interface posts_aggregate_bool_exp {
  bool_and?: posts_aggregate_bool_exp_bool_and | null;
  bool_or?: posts_aggregate_bool_exp_bool_or | null;
  count?: posts_aggregate_bool_exp_count | null;
}

export interface posts_aggregate_bool_exp_bool_and {
  arguments: posts_select_column_posts_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: posts_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface posts_aggregate_bool_exp_bool_or {
  arguments: posts_select_column_posts_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: posts_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface posts_aggregate_bool_exp_count {
  arguments?: posts_select_column[] | null;
  distinct?: boolean | null;
  filter?: posts_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * order by aggregate values of table "posts"
 */
export interface posts_aggregate_order_by {
  count?: order_by | null;
  max?: posts_max_order_by | null;
  min?: posts_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "posts"
 */
export interface posts_arr_rel_insert_input {
  data: posts_insert_input[];
  on_conflict?: posts_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "posts". All fields are combined with a logical 'AND'.
 */
export interface posts_bool_exp {
  _and?: posts_bool_exp[] | null;
  _not?: posts_bool_exp | null;
  _or?: posts_bool_exp[] | null;
  created_at?: timestamptz_comparison_exp | null;
  data?: jsonb_comparison_exp | null;
  extra?: jsonb_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  owner_id?: String_comparison_exp | null;
  post_type?: String_comparison_exp | null;
  profile?: profiles_bool_exp | null;
  profile_id?: String_comparison_exp | null;
  push?: Boolean_comparison_exp | null;
  state_published?: StatePublished_enum_comparison_exp | null;
  text?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "posts"
 */
export interface posts_insert_input {
  created_at?: any | null;
  data?: any | null;
  extra?: any | null;
  id?: any | null;
  owner_id?: string | null;
  post_type?: string | null;
  profile?: profiles_obj_rel_insert_input | null;
  profile_id?: string | null;
  push?: boolean | null;
  state_published?: StatePublished_enum | null;
  text?: string | null;
  updated_at?: any | null;
}

/**
 * order by max() on columns of table "posts"
 */
export interface posts_max_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  owner_id?: order_by | null;
  post_type?: order_by | null;
  profile_id?: order_by | null;
  text?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * order by min() on columns of table "posts"
 */
export interface posts_min_order_by {
  created_at?: order_by | null;
  id?: order_by | null;
  owner_id?: order_by | null;
  post_type?: order_by | null;
  profile_id?: order_by | null;
  text?: order_by | null;
  updated_at?: order_by | null;
}

/**
 * on_conflict condition type for table "posts"
 */
export interface posts_on_conflict {
  constraint: posts_constraint;
  update_columns: posts_update_column[];
  where?: posts_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "profiles". All fields are combined with a logical 'AND'.
 */
export interface profiles_bool_exp {
  _and?: profiles_bool_exp[] | null;
  _not?: profiles_bool_exp | null;
  _or?: profiles_bool_exp[] | null;
  allowed_users?: jsonb_comparison_exp | null;
  approved?: Boolean_comparison_exp | null;
  city?: cities_bool_exp | null;
  city_id?: String_comparison_exp | null;
  claimed?: Boolean_comparison_exp | null;
  contact?: String_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  events?: events_bool_exp | null;
  events_aggregate?: events_aggregate_bool_exp | null;
  facebookPage_url?: String_comparison_exp | null;
  followed?: Boolean_comparison_exp | null;
  follower_count?: Int_comparison_exp | null;
  followers?: followed_bool_exp | null;
  followers_aggregate?: followed_aggregate_bool_exp | null;
  id?: String_comparison_exp | null;
  image_handle?: String_comparison_exp | null;
  instagram_username?: String_comparison_exp | null;
  main_place_id?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  next_week_count?: Int_comparison_exp | null;
  notes?: String_comparison_exp | null;
  owner_id?: String_comparison_exp | null;
  page_config?: jsonb_comparison_exp | null;
  phone?: String_comparison_exp | null;
  posts?: posts_bool_exp | null;
  posts_aggregate?: posts_aggregate_bool_exp | null;
  previewID?: String_comparison_exp | null;
  search_hint?: String_comparison_exp | null;
  shouldScrape?: Boolean_comparison_exp | null;
  slug?: String_comparison_exp | null;
  tagline?: String_comparison_exp | null;
  twitter_username?: String_comparison_exp | null;
  upcoming_count?: Int_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  website?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "profiles"
 */
export interface profiles_insert_input {
  allowed_users?: any | null;
  approved?: boolean | null;
  city?: cities_obj_rel_insert_input | null;
  city_id?: string | null;
  claimed?: boolean | null;
  contact?: string | null;
  created_at?: any | null;
  description?: string | null;
  events?: events_arr_rel_insert_input | null;
  facebookPage_url?: string | null;
  followers?: followed_arr_rel_insert_input | null;
  id?: string | null;
  image_handle?: string | null;
  instagram_username?: string | null;
  name?: string | null;
  notes?: string | null;
  owner_id?: string | null;
  page_config?: any | null;
  phone?: string | null;
  posts?: posts_arr_rel_insert_input | null;
  previewID?: string | null;
  search_hint?: string | null;
  shouldScrape?: boolean | null;
  slug?: string | null;
  tagline?: string | null;
  twitter_username?: string | null;
  updated_at?: any | null;
  website?: string | null;
}

/**
 * input type for inserting object relation for remote table "profiles"
 */
export interface profiles_obj_rel_insert_input {
  data: profiles_insert_input;
  on_conflict?: profiles_on_conflict | null;
}

/**
 * on_conflict condition type for table "profiles"
 */
export interface profiles_on_conflict {
  constraint: profiles_constraint;
  update_columns: profiles_update_column[];
  where?: profiles_bool_exp | null;
}

/**
 * Ordering options when selecting data from "profiles".
 */
export interface profiles_order_by {
  allowed_users?: order_by | null;
  approved?: order_by | null;
  city?: cities_order_by | null;
  city_id?: order_by | null;
  claimed?: order_by | null;
  contact?: order_by | null;
  created_at?: order_by | null;
  description?: order_by | null;
  events_aggregate?: events_aggregate_order_by | null;
  facebookPage_url?: order_by | null;
  followed?: order_by | null;
  follower_count?: order_by | null;
  followers_aggregate?: followed_aggregate_order_by | null;
  id?: order_by | null;
  image_handle?: order_by | null;
  instagram_username?: order_by | null;
  main_place_id?: order_by | null;
  name?: order_by | null;
  next_week_count?: order_by | null;
  notes?: order_by | null;
  owner_id?: order_by | null;
  page_config?: order_by | null;
  phone?: order_by | null;
  posts_aggregate?: posts_aggregate_order_by | null;
  previewID?: order_by | null;
  search_hint?: order_by | null;
  shouldScrape?: order_by | null;
  slug?: order_by | null;
  tagline?: order_by | null;
  twitter_username?: order_by | null;
  upcoming_count?: order_by | null;
  updated_at?: order_by | null;
  website?: order_by | null;
}

/**
 * Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'.
 */
export interface timestamptz_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'.
 */
export interface uuid_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
