import React from "react";
import { StyleSheet, View } from "react-native";
import { Blurhash } from "shared/components/Blurhash";

import { makeWebURI } from "./shared";

import { CloudinaryImageProps } from "./types";
import { unstable_createElement } from "react-native-web";
import { Colors } from "shared/utils/theme";

const Img = (props) => unstable_createElement("img", props);
const Div = (props) => unstable_createElement("div", props);

function NoImageReplacement({
  width,
  height,
  color = Colors.generic.black,
  style = {},
  ...rest
}) {
  return (
    <View
      style={[
        styles.container,
        { width, height, backgroundColor: color },
        style,
      ]}
      {...rest}
    />
  );
}

export interface ImageSourceType {
  handle?: string;
}

const styles = StyleSheet.create({
  container: {
    //@ts-ignore
    display: "block",
    overflow: "hidden",
  },
  img: {
    //@ts-ignore
    objectFit: "cover",
    width: "100%",
  },
});

const CloudinaryImage: React.FC<CloudinaryImageProps> = (props) => {
  const { image, style, cover = true } = props;
  const width =
    props.width ||
    (props.style && Number(StyleSheet.flatten(props.style).width));
  const height =
    props.height ||
    (props.style && Number(StyleSheet.flatten(props.style).height));

  return image && image.handle ? (
    <Div style={[styles.container, { width, height }, style]}>
      <Img
        loading={props.important ? "eager" : "lazy"}
        alt={image.alt || ""}
        style={{ width: "100%", objectFit: props.cover ? "cover" : "contain" }}
        src={makeWebURI(image.handle, width * 2, height * 2, { cover })}
        srcSet={`
          ${makeWebURI(image.handle, width, height, { cover })} 1x,
          ${makeWebURI(image.handle, width * 1.5, height * 1.5, {
            cover,
          })} 1.5x,
          ${makeWebURI(image.handle, width * 2, height * 2, { cover })} 2x,
          ${makeWebURI(image.handle, width * 3, height * 3, { cover })} 3x,
          ${makeWebURI(image.handle, width * 4, height * 4, { cover })} 4x,
        `}
      />
      {image.blurhash && (
        <View style={{ marginTop: -4 }}>
          <Blurhash blurhash={image.blurhash} width={width} height={height} />
        </View>
      )}
    </Div>
  ) : (
    <NoImageReplacement style={style} width={width} height={height} />
  );
};

function preloadImage(
  image: { handle: string; width: number; height: number; options?: any },
  important: boolean = true
) {
  console.info("web noop: preloading image");
}

export { CloudinaryImage, NoImageReplacement, preloadImage };
