import React from "react";
import { View } from "react-native";
import { Text } from "shared/components/Text";
import { Colors } from "shared/utils/theme";

function CancelledOrnament() {
  return (
    <View
      pointerEvents="none"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "hidden",
        justifyContent: "flex-start",
        alignItems: "flex-end",
      }}
    >
      <Text
        color={Colors.generic.white}
        type="captionStrong"
        style={{
          marginTop: 28,
          marginRight: -40,
          transform: [{ rotateZ: "45deg" }],
          paddingVertical: 4,
          paddingHorizontal: 48,
          backgroundColor: Colors.accent,
        }}
      >
        Abgesagt
      </Text>
    </View>
  );
}

function DraftOrnament() {
  return (
    <View
      pointerEvents="none"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "hidden",
        justifyContent: "flex-start",
        alignItems: "flex-end",
      }}
    >
      <Text
        color={Colors.generic.white}
        type="captionStrong"
        style={{
          marginTop: 28,
          marginRight: -40,
          transform: [{ rotateZ: "45deg" }],
          paddingVertical: 4,
          paddingHorizontal: 48,
          backgroundColor: Colors.ios.orange,
        }}
      >
        Entwurf
      </Text>
    </View>
  );
}
export { CancelledOrnament, DraftOrnament };
