import React from "react";
import { unstable_createElement } from "react-native-web";
import { View, StyleSheet } from "react-native";
import { Props } from "./types";
import { unshortenBase64PNG } from "shared/utils/image";

function makeDataURI(blurhash: string) {
  return `data:image/png;base64,${unshortenBase64PNG(blurhash)}`;
}

const Img = (props) => unstable_createElement("img", props);

const styles = StyleSheet.create({
  outer: { backgroundColor: "#888", overflow: "hidden" },
  img: {
    /*imageRendering: "pixelated"*/
  },
  lessBlur: {
    margin: "-20px -20px -20px -20px",
    //filter: "blur(15px) saturate(1.1) brightness(1.1) contrast(1.1)",
  },
  moreBlur: {
    margin: "-60px -60px -60px -60px",
    //filter: "blur(60px) saturate(1.1) brightness(1.1) contrast(1.1)",
  },
});

function Blurhash({
  blurhash,
  style = {},
  width,
  height,
  lessBlur = false,
  resolutionX,
  resolutionY,
  punch,
}: Props) {
  const fullDataURI = "data:image/png;base64," + unshortenBase64PNG(blurhash);
  return (
    <View style={[{ width, height }, styles.outer, style]}>
      <Img
        alt=""
        style={[
          styles.img,
          lessBlur ? styles.lessBlur : styles.moreBlur,
          {
            //@ts-ignore
            width: (width || style.width) + (lessBlur ? 40 : 120),
            //@ts-ignore
            height: (height || style.height) + (lessBlur ? 40 : 120),
          },
        ]}
        src={fullDataURI}
      />
    </View>
  );
}

export { Blurhash, makeDataURI };
