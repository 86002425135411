import React from "react";
import { View, useColorScheme, StyleSheet, Platform } from "react-native";
import { EventCardFragment } from "./_gql/EventCardFragment";
import { EventType } from "shared/data/EventType";
import { Radius, Spacing, Colors, Shadows, Dynamic } from "shared/utils/theme";
import {
  formatDateForCard,
  formatTimeOnlyForCard,
  formatDateTimeForCard,
  formatDateTimeForVoiceOver,
  formatTimeForCard,
} from "shared/utils/formatDate";

import { Text } from "shared/components/Text";
import { CloudinaryImage } from "shared/components/Image";
import { Link } from "shared/components/Link";
import { Icon } from "shared/components/Icon";
import { ProfileRow } from "shared/components/ProfileRow";
import { CancelledOrnament, DraftOrnament } from "shared/components/Ornaments";
import {
  EVENT_SCREEN_IMAGE_HEIGHT,
  EVENT_SCREEN_IMAGE_WIDTH,
} from "mobile/src/screens/EventScreen/CONSTANTS";
import { formatExhibitionRange } from "shared/utils/formatDate";
import { Blurhash } from "shared/components/Blurhash";
import { isSameDay } from "date-fns";
import take from "lodash/take";

interface EventLinkProps {
  data: EventCardFragment;
  prefetch?: boolean;
  children?: any;
}

function makeDateLine({ eventType, thisStartDate, occurrences }) {
  if (eventType === EventType.Art) {
    return formatExhibitionRange(occurrences);
  } else if (eventType === EventType.Movie) {
    return formatDateForCard(new Date(thisStartDate));
  } else {
    return formatDateTimeForCard(new Date(thisStartDate));
  }
}

function makeTimeOnlyLine({ eventType, thisStartDate, occurrences }) {
  if (eventType === EventType.Art) {
    return formatExhibitionRange(occurrences);
  } else if (eventType === EventType.Movie) {
    return formatDateForCard(new Date(thisStartDate));
  } else {
    return formatTimeOnlyForCard(new Date(thisStartDate));
  }
}

function getTimesOnDay({ date, occurrences }): string[] {
  let onDay = occurrences.filter(({ startDate }) =>
    isSameDay(new Date(startDate), new Date(date))
  );
  let times = onDay.map(({ startDate }) =>
    formatTimeForCard(new Date(startDate))
  );
  return times;
}

function makeVODate({ eventType, thisStartDate, occurrences }) {
  if (eventType === EventType.Art) {
    return formatExhibitionRange(occurrences);
  } else {
    return (
      formatDateTimeForVoiceOver(new Date(thisStartDate)) +
      (occurrences.length > 1
        ? `\n+ ${occurrences.length - 1} ${
            occurrences.length > 2 ? "weitere Termine" : "weiterer Termin"
          }`
        : "")
    );
  }
}

const EventLink: React.FC<EventLinkProps> = React.memo(
  ({
    data: { event, ...occurrence },
    prefetch = false,
    children = undefined,
  }) => {
    return (
      <Link
        resourceType={"occurrence"}
        resourceId={occurrence.id}
        screenTitle={event.name}
        prefetch={prefetch}
        track={{
          name: "Tapped EventLink",
          props: {
            actionType: "tapLink",
            resourceType: "occurrence",
            resourceId: occurrence.id,
            resourceCategory: event.type.eventType,
            resourceDate: occurrence.startDate,
            resourceName: event.name,
            resourceLocation: event.profile.name,
            message: `Tapped on Card for event ${event.name} at ${occurrence.startDate}`,
          },
        }}
      >
        {children}
      </Link>
    );
  }
);

function MostCardsWrapper({
  children,
  data,
  prefetch,
  wrapper = {},
  container = {},
}) {
  return (
    <EventLink data={data} prefetch={prefetch}>
      <Card wrapper={wrapper} container={container}>
        {children}
        {data.cancelled && <CancelledOrnament />}
        {data.event.statePublished === "DRAFT" && <DraftOrnament />}
      </Card>
    </EventLink>
  );
}

const stylesCard = StyleSheet.create({
  outer: {
    borderRadius: Radius.round,
    backgroundColor: Colors.card,
    ...Shadows.card,
  },
  inner: {
    overflow: "hidden",
    borderRadius: Radius.round,
    flex: 1,
  },
});

function Card({ children, style = {}, wrapper = {}, container = {} }) {
  return (
    <View style={[stylesCard.outer, style, wrapper]}>
      <View style={[stylesCard.inner, container]}>{children}</View>
    </View>
  );
}

const MostCardsInfo = React.memo(_MostCardsInfo) as typeof _MostCardsInfo;

const stylesMostCardsInfo = StyleSheet.create({
  name: { marginBottom: Spacing.betweenHalf },
  outerInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  innerInfo: { flexDirection: "row", alignItems: "center" },
  moreDates: { flexDirection: "row", alignItems: "center" },
});

function _MostCardsInfo({
  data,
  withCategory = true,
}: {
  data: EventCardFragment;
  withCategory?: boolean;
}) {
  return (
    <View style={{ height: 68 }}>
      {withCategory && data.event.type.eventType !== EventType.Other && (
        <Text
          type="caption"
          style={{
            marginTop: -4,
            marginBottom: 6,
            opacity: 0.7,
            textTransform: "uppercase",
          }}
        >
          {data.event.type.shortLabel}
        </Text>
      )}
      <Text
        type="smallTitle"
        numberOfLines={
          withCategory && data.event.type.eventType !== EventType.Other ? 1 : 2
        }
        style={stylesMostCardsInfo.name}
      >
        {data.event.name}
      </Text>
      <View style={stylesMostCardsInfo.outerInfo}>
        <View style={stylesMostCardsInfo.innerInfo}>
          <Text
            accessibilityLabel={makeVODate({
              eventType: data.event.type.eventType,
              occurrences: data.event.occurrences,
              thisStartDate: data.startDate,
            })}
            type="subtitle"
          >
            {makeDateLine({
              eventType: data.event.type.eventType,
              occurrences: data.event.occurrences,
              thisStartDate: data.startDate,
            })}
          </Text>
          {data.event.type.eventType !== EventType.Art &&
            data.event.occurrences.length > 1 && (
              <View style={stylesMostCardsInfo.moreDates}>
                <Text color={Colors.secondary}>{`  + ${
                  data.event.occurrences.length - 1
                } `}</Text>
                <Icon name="f-calendar" size={14} color={Colors.secondary} />
              </View>
            )}
        </View>
      </View>
    </View>
  );
}

const ROW_CARD_WIDTH = 240;

interface EventDetailProps {
  data: EventCardFragment;
  visible?: boolean;
  prefetch?: boolean;
  withCategory?: boolean;
  withSaved?: boolean;
}
const RowCard = React.memo(_RowCard) as typeof _RowCard;

function _RowCard({ data, prefetch = false }: EventDetailProps) {
  return (
    <MostCardsWrapper
      data={data}
      prefetch={prefetch}
      wrapper={{ width: ROW_CARD_WIDTH, height: ROW_CARD_WIDTH + 68 }}
      container={{ justifyContent: "flex-end" }}
    >
      <CloudinaryImage
        face={true}
        image={{
          handle: data.event.coverImage_handle || data.event.poster_handle,
          blurhash: data.event.hash,
        }}
        style={{
          width: ROW_CARD_WIDTH,
          height: ROW_CARD_WIDTH,
          position: "absolute",
          top: 0,
        }}
      />
      <View style={{ padding: Spacing.between, backgroundColor: Colors.card }}>
        <MostCardsInfo data={data} />
      </View>
    </MostCardsWrapper>
  );
}

const LIST_CARD_WIDTH = Dynamic.width - Spacing.between * 2;
const LIST_CARD_HEIGHT = EVENT_SCREEN_IMAGE_HEIGHT + 70;
function ListCard({ data, prefetch = false }: EventDetailProps) {
  return (
    <MostCardsWrapper
      data={data}
      prefetch={prefetch}
      wrapper={{
        width: LIST_CARD_WIDTH,
        height: LIST_CARD_HEIGHT,
        marginHorizontal: Spacing.between,
        marginVertical: Spacing.betweenHalf,
      }}
      container={{ justifyContent: "flex-end" }}
    >
      <CloudinaryImage
        face={true}
        image={{
          handle: data.event.coverImage_handle || data.event.poster_handle,
          blurhash: data.event.hash,
        }}
        style={{
          position: "absolute",
          top: 0,
          width: EVENT_SCREEN_IMAGE_WIDTH,
          height: EVENT_SCREEN_IMAGE_HEIGHT,
        }}
      />
      <View style={{ padding: Spacing.between, backgroundColor: Colors.card }}>
        <MostCardsInfo data={data} />
      </View>
    </MostCardsWrapper>
  );
}

const FeedCard = React.memo(_FeedCard) as typeof _FeedCard;
const FEED_CARD_WIDTH = Dynamic.width - Spacing.between * 2;
function _FeedCard({ data, prefetch = false }: EventDetailProps) {
  const scheme = useColorScheme();
  return (
    <MostCardsWrapper
      data={data}
      prefetch={prefetch}
      wrapper={{ width: FEED_CARD_WIDTH, marginVertical: Spacing.betweenHalf }}
      container={{ justifyContent: "flex-end" }}
    >
      <CloudinaryImage
        face={true}
        image={{
          handle: data.event.coverImage_handle || data.event.poster_handle,
          blurhash: data.event.hash,
        }}
        style={{
          width: EVENT_SCREEN_IMAGE_WIDTH,
          height: EVENT_SCREEN_IMAGE_HEIGHT,
        }}
      />
      <View style={{ width: "100%" }}>
        {data.event.hash && (
          <Blurhash
            style={{
              transform: [{ scaleY: -1 }],
              position: "absolute",
              top: 0,
              width: COLLAGE_CARD_WIDTH,
              height: COLLAGE_CARD_WIDTH * 3,
            }}
            blurhash={data.event.hash}
          />
        )}
        <View
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: scheme === "light" ? 0.4 : 0.2,
            backgroundColor: Colors.card,
          }}
        />
        <View style={{ padding: Spacing.between }}>
          <MostCardsInfo data={data} withCategory={false} />
        </View>
      </View>
    </MostCardsWrapper>
  );
}

const ListCard2 = React.memo(_ListCard2) as typeof _ListCard2;

const LIST_CARD_HEIGHT2 = Platform.select({ web: 180, default: 140 });
const LIST_CARD_WIDTH2 = Platform.select({
  web: "100%",
  default: (Dynamic.width - Spacing.between * 2) as unknown as string,
});

function _ListCard2({
  data,
  withCategory = true,
  prefetch = false,
}: EventDetailProps) {
  return (
    <EventLink data={data} prefetch={prefetch}>
      <Card
        wrapper={{
          width: LIST_CARD_WIDTH2,
          height: LIST_CARD_HEIGHT2,
          marginHorizontal: Platform.select({
            default: Spacing.between,
            web: 0,
          }),
          marginVertical: Platform.select({
            default: Spacing.betweenHalf,
            web: 0,
          }),
        }}
        container={{ flexDirection: "row" }}
      >
        <CloudinaryImage
          face={true}
          image={{
            handle: data.event.coverImage_handle || data.event.poster_handle,
            blurhash: data.event.hash,
          }}
          style={{ width: LIST_CARD_HEIGHT2, height: LIST_CARD_HEIGHT2 }}
        />
        <View
          style={{
            minWidth: 200,
            padding: Spacing.between,
            justifyContent: "center",
            flex: 1,
          }}
        >
          {withCategory && data.event.type.eventType !== EventType.Other && (
            <Text
              type="caption"
              style={{
                marginBottom: 6,
                opacity: 0.7,
                textTransform: "uppercase",
              }}
            >
              {data.event.type.shortLabel}
            </Text>
          )}
          <Text type="smallTitle" numberOfLines={2} style={{ marginBottom: 4 }}>
            {data.event.name}
          </Text>
          <View
            style={{
              opacity: 0.9,
              marginTop: 4,
              marginBottom: 2,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Icon
              name="f-map-pin"
              size={12}
              style={{ marginRight: 4 }}
              color={Colors.primary}
            />
            <Text
              style={{ flex: 1 }}
              accessibilityLabel={makeVODate({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
              numberOfLines={1}
              type="caption"
            >
              {data.event.placeInfo.name}
            </Text>
          </View>
          <View
            style={{ opacity: 0.9, flexDirection: "row", alignItems: "center" }}
          >
            <Icon
              name={
                data.event.type.eventType === "Art" ? "f-calendar" : "f-clock"
              }
              size={12}
              style={{ marginRight: 4 }}
              color={Colors.primary}
            />
            <Text
              accessibilityLabel={makeVODate({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
              type="caption"
            >
              {makeTimeOnlyLine({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
            </Text>
            <View style={{ flex: 1 }} />
            {data.event.type.eventType !== "Art" &&
              data.event.occurrences.length > 1 && (
                <View style={stylesMostCardsInfo.moreDates}>
                  <Text>{"+ "}</Text>
                  <Icon name="f-calendar" size={14} color={Colors.secondary} />
                  <Text color={Colors.secondary}>{` ${
                    data.event.occurrences.length - 1
                  }`}</Text>
                </View>
              )}
          </View>
        </View>
        {data.cancelled && <CancelledOrnament />}
        {data.event.statePublished === "DRAFT" && <DraftOrnament />}
      </Card>
    </EventLink>
  );
}

function ExhibitionSummaryCard({ events, prefetch = false }) {
  return (
    <Card
      wrapper={{
        marginHorizontal: Platform.select({
          default: Spacing.between,
          web: 0,
        }),
        marginVertical: Platform.select({
          default: Spacing.betweenHalf,
          web: 0,
        }),
      }}
      container={{
        paddingHorizontal: Spacing.between,
        paddingVertical: Spacing.betweenHalf,
      }}
    >
      <Text
        type="caption"
        style={{
          marginTop: 8,
          marginBottom: 4,
          opacity: 0.7,
          textTransform: "uppercase",
        }}
      >
        {"Austellung"}
      </Text>
      {events.map((e) => (
        <SmallArtCard key={e.id} data={e} prefetch={prefetch} />
      ))}
    </Card>
  );
}

const SmallArtCard = React.memo(_SmallArtCard) as typeof _SmallArtCard;

function _SmallArtCard({
  data,
  withCategory = true,
  prefetch = false,
}: EventDetailProps) {
  return (
    <EventLink data={data} prefetch={prefetch}>
      <View
        style={{
          paddingVertical: Spacing.betweenHalf,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <View
          style={{
            justifyContent: "center",
            paddingRight: Spacing.betweenHalf,
            flex: 1,
          }}
        >
          <Text type="captionStrong" numberOfLines={1}>
            {data.event.name}
          </Text>
          <View
            style={{
              opacity: 0.9,
              marginTop: 4,
              marginBottom: 2,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{ flex: 1 }}
              accessibilityLabel={makeVODate({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
              numberOfLines={1}
              type="caption"
            >
              {data.event.placeInfo.name}
            </Text>
          </View>
          <View
            style={{ opacity: 0.9, flexDirection: "row", alignItems: "center" }}
          >
            <Text
              accessibilityLabel={makeVODate({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
              type="caption"
            >
              {makeTimeOnlyLine({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
            </Text>
            <View style={{ flex: 1 }} />
            {data.event.type.eventType !== "Art" &&
              data.event.occurrences.length > 1 && (
                <View style={stylesMostCardsInfo.moreDates}>
                  <Text>{"+ "}</Text>
                  <Icon name="f-calendar" size={14} color={Colors.secondary} />
                  <Text color={Colors.secondary}>{` ${
                    data.event.occurrences.length - 1
                  }`}</Text>
                </View>
              )}
          </View>
        </View>
        <View style={{ borderRadius: 8, overflow: "hidden" }}>
          <CloudinaryImage
            face={true}
            image={{
              handle: data.event.coverImage_handle || data.event.poster_handle,
              blurhash: data.event.hash,
            }}
            style={{ width: 64, height: 64 }}
          />
        </View>
        {data.cancelled && <CancelledOrnament />}
        {data.event.statePublished === "DRAFT" && <DraftOrnament />}
      </View>
    </EventLink>
  );
}

const MovieCard = React.memo(_MovieCard) as typeof _MovieCard;

const MOVIE_CARD_WIDTH2 = Platform.select({
  web: "100%",
  default: (Dynamic.width - Spacing.between * 2) as unknown as string,
});
const MOVIE_CARD_HEIGHT2 = Platform.select({ default: 200, web: 180 });
function _MovieCard({
  data,
  withCategory = true,
  prefetch = false,
}: EventDetailProps) {
  const times = getTimesOnDay({
    date: data.startDate,
    occurrences: data.event.occurrences,
  });
  return (
    <EventLink data={data} prefetch={prefetch}>
      <Card
        wrapper={{
          width: MOVIE_CARD_WIDTH2,
          height: MOVIE_CARD_HEIGHT2,
          marginHorizontal: Platform.select({
            default: Spacing.between,
            web: 0,
          }),
          marginVertical: Platform.select({
            default: Spacing.betweenHalf,
            web: 0,
          }),
        }}
        container={{ flexDirection: "row" }}
      >
        <CloudinaryImage
          face={true}
          image={{
            handle: data.event.poster_handle || data.event.coverImage_handle,
            blurhash: data.event.hash,
          }}
          style={{
            width: MOVIE_CARD_HEIGHT2 * 0.7,
            height: MOVIE_CARD_HEIGHT2,
          }}
        />
        <View
          style={{
            padding: Spacing.between,
            paddingRight: 0,
            justifyContent: "center",
            flex: 1,
          }}
        >
          <Text
            accessibilityLabel={makeVODate({
              eventType: data.event.type.eventType,
              occurrences: data.event.occurrences,
              thisStartDate: data.startDate,
            })}
            style={{ marginBottom: Spacing.betweenHalf, paddingRight: 8 }}
            type="subtitle"
          >
            {makeDateLine({
              eventType: data.event.type.eventType,
              occurrences: data.event.occurrences,
              thisStartDate: data.startDate,
            })}
          </Text>
          <Text
            type="smallTitle"
            numberOfLines={2}
            style={{ marginBottom: Spacing.betweenHalf }}
          >
            {data.event.name}
          </Text>

          <View
            style={{
              flexDirection: "row",
              flexWrap: Platform.select({ default: "wrap", web: "nowrap" }),
            }}
          >
            {take(times, 4).map((time, index) => (
              <View
                key={index}
                style={{
                  padding: 4,
                  marginRight: 6,
                  marginVertical: 4,
                  borderColor: Colors.secondary,
                  borderWidth: 2,
                  borderRadius: Radius.sharp,
                }}
              >
                <Text
                  type="subtitle"
                  style={{ fontVariant: ["tabular-nums"], textAlign: "center" }}
                >
                  {time}
                </Text>
              </View>
            ))}
            {times.length > 5 && (
              <View
                style={{
                  paddingVertical: 4,
                  paddingHorizontal: 8,
                  marginRight: 6,
                  marginVertical: 4,
                  borderColor: Colors.secondary,
                  borderWidth: 2,
                  borderRadius: Radius.sharp,
                  opacity: 0.6,
                }}
              >
                <Text
                  type="subtitle"
                  style={{ fontVariant: ["tabular-nums"], textAlign: "center" }}
                >
                  {`+ ${times.length - 5}`}
                </Text>
              </View>
            )}
          </View>
        </View>
        {data.cancelled && <CancelledOrnament />}
        {data.event.statePublished === "DRAFT" && <DraftOrnament />}
      </Card>
    </EventLink>
  );
}

const FEATURED_CARD_WIDTH = Dynamic.width * 0.75;
const FEATURED_CARD_HEIGHT = FEATURED_CARD_WIDTH * 1.3;

const FeaturedCard = React.memo(_FeaturedCard) as typeof _FeaturedCard;

function _FeaturedCard({ data, prefetch = false }: EventDetailProps) {
  const scheme = useColorScheme();
  return (
    <MostCardsWrapper
      data={data}
      prefetch={prefetch}
      wrapper={{
        width: FEATURED_CARD_WIDTH,
        height: FEATURED_CARD_HEIGHT,
      }}
    >
      <CloudinaryImage
        face={true}
        image={{
          handle: data.event.poster_handle || data.event.coverImage_handle,
          blurhash: data.event.hash,
        }}
        style={{
          width: FEATURED_CARD_WIDTH,
          height: FEATURED_CARD_WIDTH - (data.event.name.length > 28 ? 54 : 24),
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
        }}
      />
      <View
        style={{
          width: "100%",
          position: "absolute",
          bottom: 0,
        }}
      >
        {data.event.hash && (
          <Blurhash
            style={{
              backgroundColor: Colors.card,
              transform: [{ scaleY: -1 }],
              position: "absolute",
              top: 0,
              width: FEATURED_CARD_WIDTH,
              height: FEATURED_CARD_WIDTH * 3,
            }}
            blurhash={data.event.hash}
          />
        )}
        <View
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: scheme === "light" ? 0.4 : 0.2,
            backgroundColor: Colors.card,
          }}
        />
        <View
          style={{
            padding: Spacing.between,
          }}
        >
          <Text
            numberOfLines={2}
            type="longTitle"
            style={{ marginBottom: Spacing.betweenHalf }}
          >
            {data.event.name}
          </Text>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              accessibilityLabel={makeVODate({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
              type="smallTitle"
            >
              {makeDateLine({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
            </Text>
            {data.event.type.eventType !== "Art" &&
              data.event.occurrences.length > 1 && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    opacity: 0.5,
                  }}
                >
                  <Text type="smallTitle" color={Colors.primary}>{`  + ${
                    data.event.occurrences.length - 1
                  } `}</Text>
                  <Icon name="f-calendar" size={17} color={Colors.primary} />
                </View>
              )}
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: Spacing.betweenHalf,
            }}
          >
            <ProfileRow
              profile={data.event.profile}
              style={{ width: FEATURED_CARD_WIDTH - 80 }}
            />
          </View>
        </View>
      </View>
    </MostCardsWrapper>
  );
}

const SQUARE_CARD_WIDTH = Dynamic.width - Spacing.between * 2;
const SQUARE_CARD_HEIGHT = SQUARE_CARD_WIDTH;

const SquareCard = React.memo(_SquareCard) as typeof _SquareCard;

function _SquareCard({ data, prefetch = false }: EventDetailProps) {
  const scheme = useColorScheme();
  return (
    <MostCardsWrapper
      data={data}
      prefetch={prefetch}
      wrapper={{
        width: SQUARE_CARD_WIDTH,
        height: SQUARE_CARD_HEIGHT,
      }}
    >
      <CloudinaryImage
        face={true}
        image={{
          handle: data.event.coverImage_handle || data.event.poster_handle,
          blurhash: data.event.hash,
        }}
        style={{
          width: SQUARE_CARD_WIDTH,
          height: SQUARE_CARD_WIDTH - (data.event.name.length > 34 ? 116 : 90),
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
        }}
      />
      <View
        style={{
          width: "100%",
          position: "absolute",
          bottom: 0,
        }}
      >
        {data.event.hash && (
          <Blurhash
            style={{
              backgroundColor: Colors.card,
              transform: [{ scaleY: -1 }],
              position: "absolute",
              top: 0,
              width: SQUARE_CARD_WIDTH,
              height: SQUARE_CARD_WIDTH * 3,
            }}
            blurhash={data.event.hash}
          />
        )}
        <View
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: scheme === "light" ? 0.4 : 0.2,
            backgroundColor: Colors.card,
          }}
        />
        <View
          style={{
            padding: Spacing.between,
          }}
        >
          <Text
            numberOfLines={2}
            type="longTitle"
            style={{ marginBottom: Spacing.betweenHalf }}
          >
            {data.event.name}
          </Text>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              accessibilityLabel={makeVODate({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
              type="smallTitle"
            >
              {makeDateLine({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
            </Text>
            {data.event.type.eventType !== "Art" &&
              data.event.occurrences.length > 1 && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    opacity: 0.5,
                  }}
                >
                  <Text type="smallTitle" color={Colors.primary}>{`  + ${
                    data.event.occurrences.length - 1
                  } `}</Text>
                  <Icon name="f-calendar" size={17} color={Colors.primary} />
                </View>
              )}
          </View>
        </View>
      </View>
    </MostCardsWrapper>
  );
}

const COLLAGE_CARD_WIDTH = Dynamic.width - Spacing.between * 2;
const COLLAGE_CARD_HEIGHT = COLLAGE_CARD_WIDTH * 1.3;

const CollageCard = React.memo(_CollageCard) as typeof _CollageCard;

function _CollageCard({ data, prefetch = false }: EventDetailProps) {
  const scheme = useColorScheme();
  return (
    <MostCardsWrapper
      data={data}
      prefetch={prefetch}
      wrapper={{
        width: COLLAGE_CARD_WIDTH,
      }}
    >
      <CloudinaryImage
        face={true}
        image={{
          handle: data.event.coverImage_handle || data.event.poster_handle,
          blurhash: data.event.hash,
        }}
        style={{
          width: COLLAGE_CARD_WIDTH,
          height: COLLAGE_CARD_WIDTH,
        }}
      />
      <View
        style={{
          width: "100%",
        }}
      >
        {data.event.hash && (
          <Blurhash
            style={{
              backgroundColor: Colors.card,
              transform: [{ scaleY: -1 }],
              position: "absolute",
              top: 0,
              width: COLLAGE_CARD_WIDTH,
              height: COLLAGE_CARD_WIDTH * 3,
            }}
            blurhash={data.event.hash}
          />
        )}
        <View
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: scheme === "light" ? 0.4 : 0.2,
            backgroundColor: Colors.card,
          }}
        />
        <View
          style={{
            padding: Spacing.between,
          }}
        >
          <Text
            numberOfLines={2}
            type="longTitle"
            style={{ marginBottom: Spacing.betweenHalf }}
          >
            {data.event.name}
          </Text>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              accessibilityLabel={makeVODate({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
              type="smallTitle"
            >
              {makeDateLine({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
            </Text>
            {data.event.type.eventType !== "Art" &&
              data.event.occurrences.length > 1 && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    opacity: 0.5,
                  }}
                >
                  <Text type="smallTitle" color={Colors.primary}>{`  + ${
                    data.event.occurrences.length - 1
                  } `}</Text>
                  <Icon name="f-calendar" size={17} color={Colors.primary} />
                </View>
              )}
          </View>
        </View>
      </View>
    </MostCardsWrapper>
  );
}

const WebProfileCard = React.memo(_WebProfileCard) as typeof _WebProfileCard;

const stylesWebProfileCard = StyleSheet.create({
  container: { width: "100%" },
  wrapper: { width: "100%" },
  image: {
    width: "100%",
    height: "auto",
    marginBottom: 80,
    aspectRatio: 1,
  },
  outerInfo: {
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  blurhash: {
    backgroundColor: Colors.card,
    transform: [{ scaleY: -1 }],
    position: "absolute",
    top: 0,
    width: "100%",
    height: 2000,
  },
  bg: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: Colors.card,
  },
  padding: {
    padding: Spacing.between,
  },
  name: { marginBottom: Spacing.betweenHalf },
  infoRow: { flexDirection: "row", alignItems: "center" },
  moreEvents: {
    flexDirection: "row",
    alignItems: "center",
    opacity: 0.5,
  },
});

function _WebProfileCard({
  data,
  prefetch = false,
  visible = false,
}: EventDetailProps) {
  return (
    <MostCardsWrapper
      data={data}
      prefetch={prefetch}
      wrapper={stylesWebProfileCard.wrapper}
      container={stylesWebProfileCard.container}
    >
      <CloudinaryImage
        face={true}
        important={visible}
        image={{
          handle: data.event.coverImage_handle || data.event.poster_handle,
        }}
        width={400}
        height={400}
        style={stylesWebProfileCard.image}
      />
      <View style={stylesWebProfileCard.outerInfo}>
        {data.event.hash && (
          <Blurhash
            style={stylesWebProfileCard.blurhash}
            blurhash={data.event.hash}
          />
        )}
        <View
          style={[
            stylesWebProfileCard.bg,
            {
              opacity: 0.3,
            },
          ]}
        />
        <View style={stylesWebProfileCard.padding}>
          <Text
            numberOfLines={2}
            type="longTitle"
            style={stylesWebProfileCard.name}
          >
            {data.event.name}
          </Text>
          <View style={stylesWebProfileCard.infoRow}>
            <Text
              accessibilityLabel={makeVODate({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
              type="smallTitle"
            >
              {makeDateLine({
                eventType: data.event.type.eventType,
                occurrences: data.event.occurrences,
                thisStartDate: data.startDate,
              })}
            </Text>
            {data.event.type.eventType !== "Art" &&
              data.event.occurrences.length > 1 && (
                <View style={stylesWebProfileCard.moreEvents}>
                  <Text type="smallTitle" color={Colors.primary}>{`  + ${
                    data.event.occurrences.length - 1
                  } `}</Text>
                  <Icon name="f-calendar" size={17} color={Colors.primary} />
                </View>
              )}
          </View>
        </View>
      </View>
    </MostCardsWrapper>
  );
}

export {
  EventLink,
  RowCard,
  FeaturedCard,
  CollageCard,
  WebProfileCard,
  SquareCard,
  ListCard,
  FeedCard,
  ListCard2,
  ROW_CARD_WIDTH,
  FEATURED_CARD_WIDTH,
  MovieCard,
  Card,
  SmallArtCard,
  ExhibitionSummaryCard,
};
