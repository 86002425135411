import { formatWithOptions } from "date-fns/fp";
import {
  startOfDay,
  formatDistance,
  differenceInCalendarDays,
  isSameYear,
  format as formatDFNS,
  addHours,
} from "date-fns";
import de from "date-fns/locale/de";

const format = formatWithOptions({ locale: de });

const startToday = () => addHours(startOfDay(new Date()), 2);

const startOfDayFix = (date: Date) => addHours(startOfDay(date), 2);

const weekdayStyle = "EEEE";
const monthStyle = "MMMM";

const formatDateWithHeaderStyle = (date: Date) => {
  const delta = differenceInCalendarDays(date, today());
  switch (delta) {
    case -1:
      return "Gestern";
    case 0:
      return "Heute";
    case 1:
      return "Morgen";
    default:
      return format(weekdayStyle + ", d. MMMM", date);
  }
};

function formatExhibitionRange(occurrences) {
  const first = occurrences[0];
  const last = occurrences[occurrences.length - 1];
  const yearFirst = !isSameYear(new Date(first.startDate), new Date());
  const yearLast = !isSameYear(new Date(last.startDate), new Date());
  return (
    format("d. MMMM" + (yearFirst ? " '’'yy" : ""), new Date(first.startDate)) +
    ` – ` +
    format("d. MMMM" + (yearLast ? " '’'yy" : ""), new Date(last.startDate))
  );
}

function formatExactDateForCard(date: Date) {
  if (isSameYear(date, new Date())) {
    return format(`d. MMM`, date);
  } else {
    return format(`d. MMM yyyy`, date);
  }
}

function formatDateForTile(date: Date) {
  return formatExactDateForCard(date);
}

function formatTimeForVoiceOver(date: Date) {
  return format("HH", date) + " Uhr " + format("mm", date);
}

function formatDateTimeForVoiceOver(date: Date, endDate?: Date) {
  let dateString = "";
  if (isSameYear(date, new Date())) {
    dateString = format(`EEEEEEE, d. MMMM`, date);
  } else {
    dateString = format(`EEEEEEE, d. MMMM yyyy`, date);
  }
  let timeString = "";
  if (endDate) {
    timeString =
      " von " +
      formatTimeForVoiceOver(date) +
      " bis " +
      formatTimeForVoiceOver(endDate);
  } else {
    timeString = " um " + formatTimeForVoiceOver(date);
  }
  return dateString + timeString;
}

const formatDateForList = format(`d. ${monthStyle} H:mm`) as (
  date: Date
) => string;
const formatTime = format("HH:mm") as (date: Date) => string;
const formatShortDate = format("d. MMMM") as (date: Date) => string;
const formatMonthName = format("MMMM") as (date: Date) => string;

function formatMonthNameWithYear(date: Date) {
  if (isSameYear(new Date(), date)) {
    return format("MMMM", date);
  } else {
    return format("MMMM yyyy", date);
  }
}

function formatTimeAgo(date: Date) {
  return formatDistance(date, new Date(), {
    locale: de,
    addSuffix: true,
  });
}

const formatDateTimeForCard = (date: Date) => {
  let day = formatDateForCard(date);
  let time = formatTimeForCard(date);
  return `${day}, ${time}`;
};

const formatTimeOnlyForCard = (date: Date) => {
  let day = formatDateForCard(date);
  let time = formatTimeForCard(date);
  return `${time}`;
};

const formatDateForCard = (date: Date) => {
  const delta = differenceInCalendarDays(date, today());
  let day;
  switch (delta) {
    case -1:
      day = "Gestern";
      break;
    case 0:
      day = "Heute";
      break;
    case 1:
      day = "Morgen";
      break;
    default:
      day = formatExactDateForCard(date);
      break;
  }
  return day;
};

function formatTimeForCard(date) {
  return format("HH:mm", date);
}

const today = () => startOfDay(new Date());

export {
  formatMonthName,
  formatDateWithHeaderStyle,
  formatDateForCard,
  formatDateForList,
  formatTime,
  formatShortDate,
  formatDateTimeForCard,
  formatDateTimeForVoiceOver,
  formatTimeAgo,
  format,
  formatDateForTile,
  formatTimeForCard,
  formatMonthNameWithYear,
  formatExhibitionRange,
  startToday,
  formatTimeOnlyForCard,
  startOfDayFix,
};
