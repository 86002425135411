import React from "react";
import { View, ViewProps, PixelRatio } from "react-native";
import { Cloudinary } from "cloudinary-core";
import Constants from "expo-constants";
import { Colors } from "shared/utils/theme";

const cl = new Cloudinary({
  cloud_name: Constants.expoConfig.extra.cloudinaryCloudName,
});

function makeCloudinaryURI(
  handle: string,
  width: number,
  height: number,
  options: {
    cover?: boolean;
    faces?: boolean;
    round?: boolean;
  } = {}
) {
  const { faces = true, round = false, cover = true } = options;
  return cl.url(handle, {
    format: "webp",
    secure: true,
    transformation: [
      {
        width: Math.round(PixelRatio.getPixelSizeForLayoutSize(width)),
        height: Math.round(PixelRatio.getPixelSizeForLayoutSize(height)),
        quality: "auto",
        radius: round ? "max" : "0",
        crop: cover ? "fill" : undefined,
        resize: cover ? undefined : "fit",
        flags: "immutable_cache",
        gravity: faces && !cover ? "faces" : undefined,
      },
      ...(round
        ? [
            {
              background: "white",
              crop: "pad",
              width: Math.round(width),
              height: Math.round(height),
            },
          ]
        : []),
    ],
  });
}

function makeWebURI(
  handle,
  width,
  height,
  options: {
    cover?: boolean;
    faces?: boolean;
    round?: boolean;
  } = {}
) {
  const { faces = true, round = false, cover = true } = options;
  return cl.url(handle, {
    secure: true,
    transformation: [
      {
        fetch_format: "auto",
        width: Math.round(width),
        height: Math.round(height),
        radius: round ? "max" : "0",
        crop: cover ? "fill" : undefined,
        resize: cover ? undefined : "fit",
        flags: "immutable_cache",
        gravity: faces && !cover ? "faces" : undefined,
      },
      ...(round
        ? [
            {
              background: "white",
              crop: "pad",
              width: Math.round(width),
              height: Math.round(height),
            },
          ]
        : []),
    ],
  });
}

interface NoImageReplacementProps extends ViewProps {
  width: number;
  height: number;
  color?: string;
  style?: any;
}

const NoImageReplacement: React.FC<NoImageReplacementProps> = React.memo(
  ({ width, height, color = Colors.generic.black, style = {}, ...rest }) => {
    return (
      <View
        style={[
          style,
          {
            backgroundColor: color,
            height,
            width,
          },
        ]}
        {...rest}
      />
    );
  }
);

export { NoImageReplacement, makeCloudinaryURI, makeWebURI };
