import React from "react";
import { View, Platform } from "react-native";
import { Text } from "shared/components/Text";
import { CloudinaryImage } from "shared/components/Image";
import { Colors, Spacing } from "shared/utils/theme";
import { Link } from "shared/components/Link";
import {
  FollowButton,
  useFollowMutation,
} from "shared/components/FollowButton";
import { Icon } from "shared/components/Icon";
interface ProfileRowProps {
  profile: any;
  style?: any;
  follow?: boolean;
  prefetch?: boolean;
  animate?: boolean;
}

function AllProfilesCircle() {
  return (
    <Link resourceType="profileList">
      <View
        style={[
          {
            width: 108,
            height: 100,
            alignItems: "center",
            marginRight: 4,
          },
        ]}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: 74,
            height: 74,
            borderRadius: 74,
            backgroundColor: Colors.transparent.primary[2],
          }}
        >
          <Icon name="f-chevron-right" size={40} color={Colors.primary} />
        </View>
        <Text
          numberOfLines={1}
          type="captionStrong"
          style={{ textAlign: "center", marginTop: Spacing.betweenHalf }}
        >
          Alle Profile
        </Text>
      </View>
    </Link>
  );
}

function ProfileCircle({
  profile,
  style = {},
  prefetch = false,
}: ProfileRowProps) {
  if (!profile.claimed) {
    return <View />;
  }
  return (
    <Link
      key={profile.id}
      prefetch={prefetch}
      resourceType="profile"
      resourceId={profile.slug}
    >
      <View
        style={[
          {
            width: 108,
            height: 100,
            alignItems: "center",
            marginRight: 4,
          },
          style,
        ]}
      >
        <CloudinaryImage
          image={{ handle: profile.image_handle }}
          width={80}
          height={80}
          style={{ borderRadius: 88 }}
        />
        <Text
          numberOfLines={1}
          type="captionStrong"
          style={{ textAlign: "center", marginTop: Spacing.betweenHalf }}
        >
          {profile.slug}
        </Text>
      </View>
    </Link>
  );
}

const ProfileRow = React.memo(_ProfileRow) as typeof _ProfileRow;

function _ProfileRow({
  profile,
  style = {},
  follow = false,
  prefetch = false,
  animate = false,
}: ProfileRowProps) {
  if (!profile.claimed) {
    return <View />;
  }
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flex: Platform.select({ web: undefined, default: 1 }),
      }}
    >
      <Link
        screenTitle={profile.name}
        prefetch={prefetch}
        style={{ flex: 1 }}
        resourceType="profile"
        track={{
          name: "Tapped Profile Link",
          props: {
            resourceType: "profile",
            resourceId: profile.id,
            resourceName: profile.name,
          },
        }}
        resourceId={profile.slug}
      >
        <View
          style={[
            {
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
              marginRight: Spacing.betweenHalf,
            },
            style,
          ]}
        >
          <CloudinaryImage
            important={true}
            image={{ handle: profile.image_handle }}
            style={{
              opacity: profile.claimed ? 1 : 0,
              width: 20,
              height: 20,
              borderRadius: 20,
              marginRight: Spacing.betweenHalf,
            }}
          />
          {profile.claimed && (
            <Text
              numberOfLines={1}
              type="subtitleStrong"
              style={{ opacity: 1, flex: 1 }}
              color={Colors.primary}
            >
              {profile.name}
            </Text>
          )}
        </View>
      </Link>
      {follow && profile.claimed && (
        <FollowButton
          animate={animate}
          name={profile.name}
          id={profile.id}
          // @ts-ignore
          followed={profile.followed}
        />
      )}
    </View>
  );
}

function BackToProfileRow({ profile }: ProfileRowProps) {
  if (!profile.claimed) {
    return <View />;
  }
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        marginLeft: -8,
        marginVertical: Spacing.between / 2,
      }}
    >
      <Link scroll={false} resourceType="profile" resourceId={profile.slug}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <CloudinaryImage
            important={true}
            image={{ handle: profile.image_handle }}
            style={{ width: 40, height: 40, borderRadius: 40 }}
          />
          <View style={{ marginLeft: Spacing.between / 2 }}>
            <Text type="bodyStrong">{profile.name}</Text>
            {profile.tagline && <Text type="body">{profile.tagline}</Text>}
          </View>
        </View>
      </Link>
    </View>
  );
}

const ProfileRowLarge = React.memo(_ProfileRowLarge) as typeof _ProfileRowLarge;

function _ProfileRowLarge({
  profile,
  animate = false,
  style = {},
  follow = true,
  prefetch = false,
}: ProfileRowProps) {
  if (!profile.claimed) {
    return <View />;
  }
  return (
    <View
      style={[
        {
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          opacity: profile.claimed ? 1 : 0.7,
        },
        style,
      ]}
    >
      <Link
        screenTitle={profile.name}
        prefetch={prefetch}
        style={{ flex: 1 }}
        track={{
          name: "Tapped Profile Link",
          props: {
            resourceType: "profile",
            resourceId: profile.id,
            resourceName: profile.name,
          },
        }}
        resourceType="profile"
        resourceId={profile.slug}
      >
        <View style={[{ flexDirection: "row", alignItems: "center" }]}>
          <CloudinaryImage
            important={true}
            image={{ handle: profile.image_handle }}
            style={{
              opacity: profile.claimed ? 1 : 0,
              width: 36,
              height: 36,
              borderRadius: 20,
              marginRight: Spacing.betweenHalf,
            }}
          />
          {profile.claimed && (
            <View style={{ flex: 1, marginRight: Spacing.betweenHalf }}>
              <Text numberOfLines={1} type="smallTitle" color={Colors.primary}>
                {profile.name}
              </Text>
              {profile.tagline && (
                <Text
                  numberOfLines={1}
                  style={{ flex: 1 }}
                  type="subtitle"
                  color={Colors.primary}
                >
                  {profile.tagline}
                </Text>
              )}
            </View>
          )}
        </View>
      </Link>
      {follow && profile.claimed && (
        <FollowButton
          animate={animate}
          name={profile.name}
          id={profile.id}
          // @ts-ignore
          followed={profile.followed}
        />
      )}
    </View>
  );
}

export {
  AllProfilesCircle,
  ProfileRow,
  BackToProfileRow,
  ProfileRowLarge,
  FollowButton,
  useFollowMutation,
  ProfileCircle,
};
